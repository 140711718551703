<template>
    <div>
        <div id="fixedbox">
            <div class="navbox">
                <div class="nav">
                    <div class="navitem" @click="toysyz">影视预展</div>
                    <div class="navitem" @click="toxmypl">意向征集</div>
                    <div class="navitem navactive">成交公告</div>

                    <div class="navxian"></div>
                </div>
            </div>


            <!-- <div class="serchbigbox">
                <div class="serchbox">
                    <img src="../../assets/img/serchicon.png" alt="">
                    <input type="text" placeholder="搜索电影" v-model="input">
                </div>
                <div class="serchtxt" @click="toSerch">搜索</div>
            </div>  -->
        </div>

        



        <div class="movielist" v-if="moviefrom[0]">

            <!-- <div class="xmItem">
                <div class="xmItemTop">
                    <p class="xmItemName">武昌区解放路446附2-1号13.99㎡房屋三年承租权成交公告</p>
                    <div class="xmItemState">完成</div>
                </div>
            </div> -->

            <div class="movieitem" v-for="(item) in moviefrom" :key="item.imagePath">
                <router-link :to="{path:`/moviedetail/${item.id}`}">

                    <div class="movieitmebox">
                        <div class="movieitemright">
                            <img :src="item.squarePic">
                        </div>
                        
                        <div class="movieitemleft">
                            <div class="moviep">{{item.columnId_dictText}}</div>
                            <div class="movietitle">{{item.projectTitle}}</div>
                            <div class="movietime">
                                <span class="movietimeauthor">{{item.infoSource}}</span>
                                <div class="movietimedian"></div>
                                <span>{{item.pushTime | dataFilter}}</span>
                            </div>
                        </div>
                        
                    </div>
                    
                </router-link>
            </div>

        </div>

        <div v-else style="height:calc(100vh - 3rem - 0.6rem - 1rem);">

        </div>


    </div>
</template>


<script>
import {getMovie,listQa,listArticle,listBanner,listPerson,movieProjectList} from "@/api/index"
import { Loading ,Message } from 'element-ui';
export default {
    data () {
        return {
            t : null,
            clientHeight : null,
            scrollHeight : null,
            currPageNo : 1,
            pageSize : 8,
            moviefrom : [],
            movieload : true,
            input : "",
            load : null,
            total:0,
        }
    },
    methods: {
        toysyz(){
            this.$router.push({name:'ysyz'});
        },
        toxmypl(){
            this.$router.push({name:'yxzj'});
        },
        toxmgp(){
            this.$router.push({name:'xmgp'});
        },
        tocjgg(){
            this.$router.push({name:'cjgg'});
        },
        windowscroll(){
            this.t = document.documentElement.scrollTop || document.body.scrollTop;
            this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
            this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            //console.log(this.t + this.clientHeight,this.scrollHeight)
            var url = this.$route.path

            if(this.scrollHeight > this.clientHeight && this.t + this.clientHeight + 200  >= this.scrollHeight) {
                if(this.movieload && this.moviefrom.length && this.moviefrom.length < this.total){
                    if(!this.$route.params.movieId){
                        if(url.indexOf("ysyz") != -1){
                            this.showLoading()
                            this.movieload = false;
                            this.currPageNo += 1;
                            movieProjectList({
                                "columnId":'1660578119793631233',
                                "pageNo": this.currPageNo,
                                "pageSize": this.pageSize
                            }).then(res => {
                                this.moviefrom = res.result.records;
                                this.total = res.result.total;
                                this.load && this.load.close();
                            })
                            setTimeout(() => {
                                this.movieload = true;
                            },3000)


                        }
                    }

                }
                
            }


        },
        toSerch(){
            //console.log(this.input)
            this.showLoading()
            getMovie({
                "data": {
                    "keyWords": this.input,
                    "isShow": "1",
                },
                "mapParam": {},
                "reqPage": {
                    "currPageNo": this.currPageNo,
                    "pageSize": this.pageSize
                }
            }).then(res => {
                this.moviefrom = res.records
                this.total = res.total
                this.load && this.load.close();
            })
        },
        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
    },
    mounted () {

        this.showLoading()


        // let head = document.getElementsByTagName('head');
        // let meta = document.createElement('meta');
        // document.querySelector('meta[name="keywords"]').setAttribute('content', "影片推荐-湖北引未来影视")
        // document.querySelector('meta[name="description"]').setAttribute('content', "影片推荐-湖北引未来影视")
        // meta.content = {
        //     keywords:"影片推荐-湖北引未来影视",
        //     description:"影片推荐-湖北引未来影视",
        // };
        // head[0].appendChild(meta)
        // document.title = "影片推荐-湖北引未来影视";


        this.t = document.documentElement.scrollTop || document.body.scrollTop;
        this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        window.addEventListener('scroll',this.windowscroll)

        movieProjectList({
            "columnId":'1660578119793631233',
            "pageNo": this.currPageNo,
            "pageSize": this.pageSize
        }).then(res => {
            this.moviefrom = res.result.records;
            this.total = res.result.total;
            this.load && this.load.close();
        })

        // getMovie({
        //     "data": {
        //         "keyWords": "",
        //         "isShow": "1",
        //     },
        //     "mapParam": {},
        //     "reqPage": {
        //         "currPageNo": this.currPageNo,
        //         "pageSize": this.pageSize
        //     }
        // }).then(res => {
            // this.moviefrom = res.records
            // this.total = res.total
            //this.load && this.load.close();
        //})

    },
    filters: {
        movieTypeFilters: function (value) {
            if (!value) return ''
                switch(value){
                case 1:
                    return "院线电影"
                    break;
                case 2:
                    return "网络电影"
                    break;
                default:
                    return "error"
            }
        },
        shootStatusFilters: function (value) {
            if (!value) return ''
                switch(value){
                case 1:
                    return "未上映"
                    break;
                case 2:
                    return "已上映"
                    break;
                default:
                    return "error"
            }
        },
        dataFilter:function(val){
            if (!val) return ''
            return val.substring(0,10)
        }
    },
}
</script>


<style lang="scss" scoped>
#fixedbox{
    width: 100%;
    background: #FFFFFF;
    position: sticky;
    top: 1.08rem;
    z-index: 997;
}

.serchbigbox{
    width: 100%;
    height: 0.96rem;
    padding: 0 0.22rem;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.serchbigbox .serchbox{
    width: 6.32rem;
    height: 0.56rem;
    background: #F8F8F8;
    border-radius: 3.62rem;
    overflow: hidden;
    display: flex;
    align-items: center;
}
.serchbigbox .serchbox img{
    width: 0.28rem;
    height: 0.3rem;
    margin-left: 0.2rem;
    margin-right: 0.18rem;
}
.serchbigbox .serchbox input{
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.34rem;
    color: #252525;
    outline: none;
    border: none;
    background: none;
}
.serchbigbox .serchbox input::-webkit-input-placeholder{
    color:#CACACA;
}
.serchbigbox .serchbox input::-moz-placeholder{
    color:#CACACA;
}
.serchbigbox .serchbox input:-moz-placeholder{
    color:#CACACA;
}
.serchbigbox .serchbox input:-ms-input-placeholder{
    color:#CACACA;
}
.serchbigbox .serchtxt{
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.34rem;
    color: #CACACA;
}



.navbox{
    width: 100%;
    height: 0.6rem;
    background-color: #fff;
    position: relative;
}
.nav{
    width: 100%;
    height: 0.6rem;
    display: flex;
    background-color: #fff;
    position: relative;
    z-index: 997;
    border-bottom: 0.02rem solid rgba(112, 112, 112, .2);
}
.navfixed{
    position: fixed;
    top: 1.08rem;
}
.navitem{
    // width: 1.04rem;
    font-size: 0.26rem;
    font-weight: 400;
    line-height: 0.6rem;
    color: #343434;
    white-space: nowrap;
    margin: 0 0.23rem;
    text-align: center;
    padding: 0;
}
.navactive{
    color: #A30001;
}
.navxian{
    width: 0.46rem;
    height: 0.04rem;
    background: #A30001;
    border-radius: 2.4rem;
    position: absolute;
    bottom: -0.01rem;
    left: calc(0.23rem * 5 + 1.04rem * 2 + 0.52rem - 0.23rem);
    transition: .4s ease;
}




.movielist{
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 0 0.22rem;
    box-sizing: border-box;
    background: #F6F6F6;
    padding-top: .1rem;
    min-height:calc(100vh - 3rem - 0.6rem - 1rem);
    .xmItem{
        width: 100%;
        padding: 0.2rem 0.22rem 0.2rem;
        border-bottom: 1px solid #E6E6E7;
        box-sizing: border-box;
        border-radius: .08rem;
        background-color: #FFFFFF;
        margin-bottom: .1rem;
        .xmItemTop{
            display: flex;
            font-size: .32rem;
            color: #2D2D2F;
            margin-bottom: .2rem;
            line-height: .36rem;
            .xmItemName{
                width: 4.88rem;
                display: -webkit-box;    
                -webkit-box-orient: vertical;    
                -webkit-line-clamp: 2;    
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: .44rem;
            }
            .xmItemState{
                height: .34rem;
                padding: 0.04rem 0.16rem;
                font-size: .24rem;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                background: #66C9A6;
            }
        }
    }
    .movieitem{
        width: 100%;
        padding: 0.2rem 0.22rem 0.2rem;
        border-bottom: 1px solid #E6E6E7;
        box-sizing: border-box;
        border-radius: .08rem;
        background-color: #FFFFFF;
        margin-bottom: .1rem;
        .movieitmebox{
            width: 100%;
            height: 100%;
            display: flex;

            .movieitemright{
                width: 2.42rem;
                height: 2.42rem;
                overflow: hidden;
                border-radius: 0.12rem;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }


            .movieitemleft{
                width: calc(100% - 2.42rem - 0.4rem);
                margin-left: .4rem;
                position: relative;
                overflow: hidden;
                .moviep{
                    font-size: .28rem;
                    font-weight: 400;
                    color: #8D8D8E;
                    line-height: .36rem;
                    margin-bottom: .22rem;
                }
                .movietitle{
                    font-size: 0.32rem;
                    font-weight: 400;
                    line-height: 0.4rem;
                    color: #2D2D2F;
                    margin-bottom: 0.1rem;
                    display: -webkit-box;    
                    -webkit-box-orient: vertical;    
                    -webkit-line-clamp: 3;    
                    overflow: hidden;

                }
                .movietime{
                    font-size: 0.28rem;
                    font-weight: 400;
                    line-height: 0.36rem;
                    color: #B8B8B8;
                    white-space: nowrap;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    overflow: hidden;
                    .movietimeauthor{
                        color: #A30001;
                        margin-right: .2rem;
                    }
                    .movietimedian{
                        width: .08rem;
                        height: .08rem;
                        background-color: #8D8D8E;
                        border-radius: 50%;
                        margin-right: .2rem;
                        margin-top: .12rem;
                    }
                }
            }
        
        
            
        }
        
    }
    
    



}




</style>